import React from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import Traec from "traec";

import { setNavBarItems } from "traec-react/navBar";
import SideBar, { setSideBarItems } from "traec-react/sideBar";
import { ErrorBoundary } from "traec-react/errors";

import { ProjectRouter } from "./router";
import { Footer } from "AppSrc/footer";

import { getProjectProps } from "AppSrc/project/utils";
import WPTree from "./wptree";

import {
  ProjectPermission,
  getProjectPermissions,
  projectPermissionFilter,
  projectPermissionCheck
} from "traec/utils/permissions/project";

import CompanyTree from "AppSrc/tree";
import AddTracker from "./tracker";
import BootstrapSplitPane from "traec-react/utils/bootstrap/splitbs";

function ProjectSideBar({ projectId, tracker, rootRef, cref }) {
  return (
    <div className="mt-4">
      <WPTree projectId={projectId} tracker={tracker} rootRef={rootRef} cref={cref} />
    </div>
  );
}

function ProjectMainPanel({ projectId, tracker, dispatch }) {
  if (!tracker) {
    return <AddTracker projectId={projectId} dispatch={dispatch} />;
  }
  return <ProjectRouter />;
}

class ProjectDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      baseUrl: "",
      fetchedUrls: {},
      setSideBar: false,
      setNavBar: false,
      showSideBar: true
    };

    this.requiredFetches = [
      new Traec.Fetch("project_tracker", "list"),
      new Traec.Fetch("tracker_branch", "list"),
      new Traec.Fetch("company", "read"),
      new Traec.Fetch("company", "list"),
      new Traec.Fetch(
        "project",
        "read",
        {},
        {
          preUpdateHook: args => ({ ...args, projectId: this.props.match.params._projectId })
        }
      )
    ];
  }

  componentDidMount() {
    const { projectId } = this.props.match.params;
    projectPermissionCheck(projectId, false, []);

    Traec.fetchRequiredFor(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { projectId, styles } = this.props;

    this.setStyles();

    projectPermissionCheck(projectId, false, []);

    let { wpackBase } = this.getBaseUrls();
    if (prevState.baseUrl !== wpackBase) {
      this.setState({
        setSideBar: false,
        setNavBar: false
      });
    }
    this.setNavBar();
    this.setSideBar();

    Traec.fetchRequiredFor(this);
  }

  setStyles() {
    let { setStyles, dispatch } = this.props;
    if (!setStyles) {
      return null;
    }
    //console.log("UPDATING GLOBAL STYLES", setStyles.toJS());
    dispatch({
      type: "UI_SET_IN",
      payload: setStyles,
      stateParams: { itemPath: `styles` }
    });
  }

  shouldForceUpdate(navBarLinks) {
    if (!this.state.setNavBar) {
      return false;
    }

    try {
      let linksString = JSON.stringify(navBarLinks);
      let propsString = JSON.stringify(this.props.navBarLinks.toJS());
      if (linksString !== propsString) {
        return true;
      }
    } catch (e) {
      return false;
    }
  }

  setNavBar(forceUpdate = false) {
    let navBarLinks = this.navBarLinks();

    forceUpdate = this.shouldForceUpdate(navBarLinks);
    if ((!this.state.setNavBar && navBarLinks) || forceUpdate) {
      this.setState({ setNavBar: true });
      this.props.dispatch(setNavBarItems(navBarLinks));
    }
  }

  setSideBar(forceUpdate = false) {
    let sideBarLinks = this.sideBarLinks();
    if ((!this.state.setSideBar && sideBarLinks) || forceUpdate) {
      this.setState({ setSideBar: true });
      this.props.dispatch(setSideBarItems(sideBarLinks));
    }
  }

  // To be set using Redux state
  navBarLinks() {
    let { projectId } = this.props;
    let { isRootRef } = this.getBaseUrls();
    let links = isRootRef ? this.projectNavBarLinks() : this.reportPackageNavBarLinks();
    return projectPermissionFilter(projectId, links);
  }

  reportPackageNavBarLinks() {
    let { isApportionmentProject } = this.props;
    let { wpackBase } = this.getBaseUrls();

    return [
      {
        label: "Reporting Package",
        requiresAdmin: false,
        to: [
          {
            label: "Dashboard",
            to: wpackBase,
            octicon: "home",
            requiresAdmin: false,
            requiredActions: ["READ_PROJECT_REPORT"]
          },
          {
            label: "Report due",
            to: `${wpackBase}/report`,
            octicon: "list-ordered",
            requiresAdmin: false,
            requiredActions: ["UPDATE_TRACKER_REF_SCORE_VALUE"]
          },
          {
            label: "Reports Submitted",
            to: `${wpackBase}/evals`,
            octicon: "checklist",
            requiresAdmin: false,
            requiredActions: ["READ_TRACKER_REF_SCORE_VALUE"]
          }
        ]
      },
      {
        label: "Admin",
        requiresAdmin: true,
        to: [
          { label: "Metrics", to: `${wpackBase}/metrics`, octicon: "server", requiresAdmin: true },
          { label: "Conversion Factors", to: `${wpackBase}/conv`, octicon: "package", requiresAdmin: true },
          { label: "Indicators", to: `${wpackBase}/indicators`, octicon: "issue-closed", requiresAdmin: true },
          isApportionmentProject
            ? {
                label: "Apportionment",
                to: `${wpackBase}/apportionment`,
                octicon: "list-unordered",
                requiresAdmin: true
              }
            : null,
          { label: "Settings", to: `${wpackBase}/details`, octicon: "gear", requiresAdmin: true }
        ]
      }
    ];
  }

  projectNavBarLinks() {
    let { projectId, rootRefId, companyId } = this.props;
    let { projectBase, wpackBase } = this.getBaseUrls();
    let projectRootBase = rootRefId ? `${projectBase}/wpack/${rootRefId.substring(0, 8)}` : `${projectBase}`;

    return [
      {
        label: "Project",
        requiresAdmin: false,
        to: [
          {
            label: "Dashboard",
            to: projectBase,
            octicon: "home",
            requiresAdmin: false,
            requiredActions: ["READ_PROJECT_REPORT"]
          },
          {
            label: "Users",
            to: `${projectBase}/members`,
            octicon: "organization",
            requiresAdmin: false,
            requiredActions: ["READ_PROJECT_MEMBER"]
          }
        ]
      },
      {
        label: "Admin",
        requiresAdmin: true,
        to: [
          { label: "Metrics", to: `${projectRootBase}/metrics`, octicon: "list-unordered", requiresAdmin: true },
          { label: "Conversion Factors", to: `${projectBase}/conv`, octicon: "package", requiresAdmin: true },
          { label: "Indicators", to: `${projectBase}/indicators`, octicon: "issue-closed", requiresAdmin: true },
          { label: "Settings", to: `${projectBase}/details`, octicon: "gear", requiresAdmin: true },
          { label: null },
          { label: "Supplier report", to: `/company/${companyId}/suppliers`, octicon: "gear", requiresAdmin: true },
          { label: null },
          {
            label: "Email Settings",
            to: `/project/${projectId}/email/settings`,
            octicon: "inbox",
            requiresAdmin: true
          },
          { label: "Email Statistics", to: `/project/${projectId}/email/report`, octicon: "mail", requiresAdmin: true }
        ]
      }
    ];
  }

  getBaseUrls() {
    let { _projectId, _refId } = this.props.match.params;
    let { isRootRef: _isRootRef, rootRefId } = this.props;
    let projectBase = `/project/${_projectId}`;
    let wpackBase = _refId ? `${projectBase}/wpack/${_refId}` : projectBase;
    let isRootRef = (_refId ? _isRootRef : true) || false;
    return { projectBase, wpackBase, isRootRef };
  }

  sideBarLinks() {
    let { wpackBase } = this.getBaseUrls();
    if (this.state.baseUrl !== wpackBase) {
      this.setState({ baseUrl: wpackBase });
    }
    return [];
  }

  render() {
    let { projectId } = this.props;

    // Check the User permissions for this project
    return (
      <ProjectPermission
        projectId={projectId}
        requiresAdmin={false}
        requiredActions={["READ_PROJECT_REPORT"]}
        showWarning={true}
      >
        <div className="container-fluid">
          <div className="row">
            <BootstrapSplitPane
              localStorageKey={`explorer-grid-split-${projectId}`}
              allowZero={true}
              pane1ClassName={"page-sidebar vh100-navbar"}
              onCollapseHook={() => {
                this.setState({ showSideBar: false });
              }}
              onExpandHook={() => {
                this.setState({ showSideBar: true });
              }}
              pane1Style={{
                borderRight: "1px solid grey"
              }}
            >
              <div>
                <ErrorBoundary>
                  <Switch>
                    <Route path="/project/:_projectId" component={CompanyTree} />
                    <Route path="/company/:_companyId" component={CompanyTree} />
                  </Switch>
                </ErrorBoundary>
              </div>

              <div>
                <ErrorBoundary>
                  <ProjectMainPanel {...this.props} />
                </ErrorBoundary>
              </div>
            </BootstrapSplitPane>
          </div>
        </div>
        <Footer />
      </ProjectPermission>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { projectId, refId } = Traec.utils.getFullIds(state, ownProps.match.params);

  let { company, project, tracker, trackerId, cref, isRootRef, rootRef, rootRefId } = getProjectProps(
    state,
    projectId,
    refId
  );
  let isApportionmentProject = project ? project.getInPath("meta_json.apportionment") : null;
  let userPermissions = getProjectPermissions(state, projectId);
  let navBarLinks = state.getInPath("ui.navbar.items");
  let companyList = state.getInPath("entities.companies.byId");
  let companyId = company ? company.get("uid") : null;

  // Get the company styles (if provided)
  let styles = project ? project.getInPath("meta_json.styles") || Traec.Im.Map() : Traec.Im.Map();
  let currentStyles = state.getInPath("ui.styles") || Traec.Im.Map();
  let setStyles = styles.equals(currentStyles) ? null : styles;

  return {
    projectId,
    project,
    company,
    companyList,
    companyId,
    tracker,
    trackerId,
    rootRefId,
    rootRef,
    isRootRef,
    refId,
    cref,
    userPermissions,
    isApportionmentProject,
    navBarLinks,
    styles,
    setStyles
  };
};

export default connect(mapStateToProps)(ProjectDetail);
